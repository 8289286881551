function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['a' + i] = e.upPoint
  })
  return {
    form
  }
}
function onSubmit(data) {
  const arr = [].concat(data.detail)
  arr.forEach((e, i) => {
    e.upPoint = data.form['a' + i]
  })

  return {
    list: arr
  }
}

export default function() {
  return {
    type: 'form',
    url: '/config/farmVipInfo/list',
    edit: '/config/farmVipInfo/updateBatch',
    onFetchInit,
    onSubmit,
    data: Array(7)
      .fill({})
      .map((e, i) => {
        return {
          key: 'a' + i,
          type: 'inputNumber',
          name: '等级' + i
        }
      })
  }
}
